import React, { useState } from "react"
import Button from "react-bootstrap/Button"
// import useRazorpay from "react-razorpay"
// import axios from "axios"
import { ToastContainer } from "react-toastify"
import { useForm } from "react-hook-form"
import _ from "lodash"

const cityDurations = [
  {
    city: "Delhi",
    durations: [
      {
        name: "day",
        price: 4000,
      },
      {
        name: "residential",
        price: 5000,
      },
    ],
  },
  {
    city: "Bihar",
    durations: [
      {
        name: "day",
        price: 6000,
      },
      {
        name: "residential",
        price: 7000,
      },
    ],
  },
]

export default function Register_test() {
  // const initialFormState = {
  //   cityDurationPair: [{}],
  // }

  const [cityDurationCouponData, setcityDurationCoupon] = useState({})

  const [totalPrice, setTotalPrice] = useState(0)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({})

  const handleEvent = async e => {
    const { name, value } = e.target
    _.set(cityDurationCouponData, name, value)

    let price = 0

    console.log(cityDurationCouponData)

    cityDurationCouponData.city_duration_pair.forEach(pair => {
      if (!pair.city || !pair.duration) return

      const cityDurationRef = cityDurations.find(e => e.city === pair.city)
      if (!cityDurationRef) return

      const duration = cityDurationRef.durations.find(
        d => d.name === pair.duration
      )
      price += duration.price
    })

    const early_bird =
      new Date().getTime() < new Date("2023-02-28T00:00:00+05:30").getTime()

    if (early_bird === true) {
      price = price - (price * 10) / 100
    }

    const getData = await fetch("https://api.csr.skillsscale.in/api/coupons")
    let json = await getData.json()
    json = json["data"]
    // console.log("json===", json)

    // Filter through the event type and coupon code.
    const data_filter = json.filter(jsonFilter => {
      if (
        jsonFilter.attributes.event_type === "event-registration" &&
        jsonFilter.attributes.coupon_code === cityDurationCouponData.coupon_code
      ) {
        return jsonFilter
      }
    })

    if (data_filter.length > 0) {
      const discount = data_filter[0].attributes.discount
      price = price - (price * discount) / 100
    }

    setcityDurationCoupon({ ...cityDurationCouponData })
    setTotalPrice(price)
  }

  // console.log("total===", totalPrice)
  // // console.log("cityDurationCouponData=s===", cityDurationCouponData)

  const onSubmit = async formData => {
    const city_duration_pair = formData.city_duration_pair.filter(
      city_duration_pair_filtered => {
        if (
          city_duration_pair_filtered.city !== false &&
          city_duration_pair_filtered.duration !== ""
        ) {
          return city_duration_pair_filtered
        }
      }
    )
  }

  return (
    <>
      <div className="container mt-5">
        <div className="card shadow-lg">
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} onChange={console.log}>
              <ToastContainer />
              {/* <div className="row">
                <div className="col-md-6">
                  <label className="mt-4">Full Name</label>
                  <input
                    placeholder="Full Name"
                    className="form-control"
                    type="text"
                    {...register("full_name", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.full_name && (
                    <p className="text-danger">Please check the First Name</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="mt-4">Designation</label>
                  <input
                    placeholder="Enter Designation"
                    className="form-control"
                    type="text"
                    {...register("designation", {
                      required: true,
                      minLength: 2,
                    })}
                  />
                  {errors.designation && (
                    <p className="text-danger">Please check the Designation</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label className="mt-4">Company Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter Company Name"
                    type="text"
                    {...register("company_name", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.company_name && (
                    <p className="text-danger">Please check the Company Name</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="mt-4">Email</label>
                  <input
                    className="form-control"
                    placeholder="Email"
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  {errors.email && (
                    <p className="text-danger">Please check the Email</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label className="mt-4">Phone Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter Phone Number"
                    type="number"
                    {...register("phone_number", {
                      required: true,
                      pattern: /^\d{10}$/,
                    })}
                  />
                  {errors.phone_number && (
                    <p className="text-danger">Please check the Phone Number</p>
                  )}
                </div>
                <div className="col-md-6">
                  <label className="mt-4">Country</label>
                  <select
                    className="form-control"
                    {...register("country", {
                      required: true,
                      minLength: 3,
                    })}
                  >
                    {Countries.map(country => (
                      <option value={country.name}>{country.name}</option>
                    ))}
                  </select>
                  {errors.country && (
                    <p className="text-danger">Please check the Country</p>
                  )}
                </div>
              </div> */}
              <h4 className="mt-4 text-center">Select Your Plan</h4>
              <div className="row">
                <div className="col-md-2">
                  <input
                    type="checkbox"
                    className="hidden"
                    value="Delhi"
                    name="city"
                    {...register("city_duration_pair[3].city", {
                      onChange: e => handleEvent(e),
                    })}
                  />
                  <label>New Delhi</label>
                </div>
                <div className="col-md-10">
                  <form>
                    <label className="">Duration</label>
                    <br />
                    <select
                      {...register("city_duration_pair[3].duration", {
                        onChange: e => handleEvent(e),
                      })}
                      className="form-control"
                    >
                      <option value="">Choose an option</option>
                      <option value="day">Day Delegate</option>
                      <option value="residential">Residential Delegate</option>
                    </select>
                    {errors.delegateDuration && (
                      <p className="text-danger">Please Select One</p>
                    )}
                  </form>
                </div>
                <br />
                <div className="col-md-2">
                  <input
                    type="checkbox"
                    className="hidden"
                    value="Bihar"
                    name="city"
                    {...register("city_duration_pair[0].city", {
                      onChange: e => handleEvent(e),
                    })}
                  />
                  <label>New NAncy</label>
                </div>
                <div className="col-md-6">
                  <form>
                    <label className="">Duration</label>
                    <br />
                    <select
                      {...register("city_duration_pair[0].duration", {
                        onChange: e => handleEvent(e),
                      })}
                      className="form-control"
                    >
                      <option value="">Choose an option</option>
                      <option value="day">Day Delegate</option>
                      <option value="residential">Residential Delegate</option>
                    </select>
                    {errors.delegateDuration && (
                      <p className="text-danger">Please Select One</p>
                    )}
                  </form>
                </div>
                <div className="col-md-4">
                  <div className="pt-3">Price: Rs. /- + 18% GST</div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className="mt-4">Do you have a Coupon Code ?</label>
                  <input
                    className="form-control"
                    placeholder="Enter Coupon Code"
                    type="text"
                    {...register("coupon_code", {
                      onChange: e => handleEvent(e),
                    })}
                  />
                  <p className="text-danger"></p>
                </div>

                <div className="col-md-4"></div>
                <div className="col-md-2">
                  <div className="pt-3">Total:</div>
                </div>
                <div className="col-md-2">
                  <div className="pt-3">Rs.{totalPrice} /-</div>
                </div>
                <label className="mt-4 fw-bold text-primary">
                  If more than one city is selected, you will get 10% discount
                  on the total amount.
                </label>

                <label className="mt-4 fw-bold text-primary">
                  Early bird registration gets 10% discount. Register before Feb
                  28th, 2023 to avail the discount!
                </label>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Button className="button mt-4" type="submit">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
